
type cryptoType = {
    [key: string]: {
        label: string
        key: string
        value: string
        items: {
            [key: string]: {
                label: string
                key: string
                value: string
            };
        }
    };
};

export const cryptoList: cryptoType = {
    'BTC': { key: 'BTC', value: 'BTC', label: 'Bitcoin', items: {} },
    'USDT': {
        key: 'USDT', value: 'USDT', label: 'Tether', items: {
            "ETH": { label: "ERC20", key: 'ETH', value: 'ETH' },
            "BNB": { label: "BEP20", key: 'BNB', value: 'BNB' },
            "TRX": { label: "TRC20", key: 'TRX', value: 'TRX' },
            /* "SOL": { label: "ERC20", key: 'SOL', value: 'SOL' }, */
            "AVAX": { label: "ERC20", key: 'AVAX', value: 'AVAX' },
            "MATIC": { label: "ERC20", key: 'MATIC', value: 'MATIC' },
        }
    },
    'ETH': { key: 'ETH', value: 'ETH', label: 'Ethereum', items: {} },
    'BNB': { key: 'BNB', value: 'BNB', label: 'Binance', items: {} },
    'TRX': { key: 'TRX', value: 'TRX', label: 'Tron', items: {} },
    /* 'SOL': { key: 'SOL', value: 'SOL', label: 'Solana', items: {} }, */
    'AVAX': { key: 'AVAX', value: 'AVAX', label: 'Avalanche', items: {} },
    'MATIC': { key: 'MATIC', value: 'MATIC', label: 'Polygon', items: {} },
    'LTC': { key: 'LTC', value: 'LTC', label: 'Litecoin', items: {} }
}