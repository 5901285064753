type fiatType = {
    [key: string]: {
        label: string
        key: string
        value: string
    };
};

export const fiatList: fiatType = {
    ANY: { label: "FIAT Currency", key: "ANY", value: "ANY" },
    AED: { label: "United Arab Emirates dirham", key: "AED", value: "AED" },
    AFN: { label: "Afghan afghani", key: "AFN", value: "AFN" },
    ALL: { label: "Albanian lek", key: "ALL", value: "ALL" },
    AMD: { label: "Armenian dram", key: "AMD", value: "AMD" },
    ANG: { label: "Netherlands Antillean guilder", key: "ANG", value: "ANG" },
    AOA: { label: "Angolan kwanza", key: "AOA", value: "AOA" },
    ARS: { label: "Argentine peso", key: "ARS", value: "ARS" },
    AUD: { label: "Australian dollar", key: "AUD", value: "AUD" },
    AWG: { label: "Aruban florin", key: "AWG", value: "AWG" },
    AZN: { label: "Azerbaijani manat", key: "AZN", value: "AZN" },
    BAM: { label: "Bosnia and Herzegovina convertible mark", key: "BAM", value: "BAM" },
    BBD: { label: "Barbados dollar", key: "BBD", value: "BBD" },
    BDT: { label: "Bangladeshi taka", key: "BDT", value: "BDT" },
    BGN: { label: "Bulgarian lev", key: "BGN", value: "BGN" },
    BHD: { label: "Bahraini dinar", key: "BHD", value: "BHD" },
    BIF: { label: "Burundian franc", key: "BIF", value: "BIF" },
    BMD: { label: "Bermudian dollar", key: "BMD", value: "BMD" },
    BND: { label: "Brunei dollar", key: "BND", value: "BND" },
    BOB: { label: "Boliviano", key: "BOB", value: "BOB" },
    BRL: { label: "Brazilian real", key: "BRL", value: "BRL" },
    BSD: { label: "Bahamian dollar", key: "BSD", value: "BSD" },
    BTN: { label: "Bhutanese ngultrum", key: "BTN", value: "BTN" },
    BWP: { label: "Botswana pula", key: "BWP", value: "BWP" },
    BYN: { label: "New Belarusian ruble", key: "BYN", value: "BYN" },
    BYR: { label: "Belarusian ruble", key: "BYR", value: "BYR" },
    BZD: { label: "Belize dollar", key: "BZD", value: "BZD" },
    CAD: { label: "Canadian dollar", key: "CAD", value: "CAD" },
    CDF: { label: "Congolese franc", key: "CDF", value: "CDF" },
    CHF: { label: "Swiss franc", key: "CHF", value: "CHF" },
    CLF: { label: "Unidad de Fomento", key: "CLF", value: "CLF" },
    CLP: { label: "Chilean peso", key: "CLP", value: "CLP" },
    CNY: { label: "Renminbi|Chinese yuan", key: "CNY", value: "CNY" },
    COP: { label: "Colombian peso", key: "COP", value: "COP" },
    CRC: { label: "Costa Rican colon", key: "CRC", value: "CRC" },
    CUC: { label: "Cuban convertible peso", key: "CUC", value: "CUC" },
    CUP: { label: "Cuban peso", key: "CUP", value: "CUP" },
    CVE: { label: "Cape Verde escudo", key: "CVE", value: "CVE" },
    CZK: { label: "Czech koruna", key: "CZK", value: "CZK" },
    DJF: { label: "Djiboutian franc", key: "DJF", value: "DJF" },
    DKK: { label: "Danish krone", key: "DKK", value: "DKK" },
    DOP: { label: "Dominican peso", key: "DOP", value: "DOP" },
    DZD: { label: "Algerian dinar", key: "DZD", value: "DZD" },
    EGP: { label: "Egyptian pound", key: "EGP", value: "EGP" },
    ERN: { label: "Eritrean nakfa", key: "ERN", value: "ERN" },
    ETB: { label: "Ethiopian birr", key: "ETB", value: "ETB" },
    EUR: { label: "Euro", key: "EUR", value: "EUR" },
    FJD: { label: "Fiji dollar", key: "FJD", value: "FJD" },
    FKP: { label: "Falkland Islands pound", key: "FKP", value: "FKP" },
    GBP: { label: "Pound sterling", key: "GBP", value: "GBP" },
    GEL: { label: "Georgian lari", key: "GEL", value: "GEL" },
    GHS: { label: "Ghanaian cedi", key: "GHS", value: "GHS" },
    GIP: { label: "Gibraltar pound", key: "GIP", value: "GIP" },
    GMD: { label: "Gambian dalasi", key: "GMD", value: "GMD" },
    GNF: { label: "Guinean franc", key: "GNF", value: "GNF" },
    GTQ: { label: "Guatemalan quetzal", key: "GTQ", value: "GTQ" },
    GYD: { label: "Guyanese dollar", key: "GYD", value: "GYD" },
    HKD: { label: "Hong Kong dollar", key: "HKD", value: "HKD" },
    HNL: { label: "Honduran lempira", key: "HNL", value: "HNL" },
    HRK: { label: "Croatian kuna", key: "HRK", value: "HRK" },
    HTG: { label: "Haitian gourde", key: "HTG", value: "HTG" },
    HUF: { label: "Hungarian forint", key: "HUF", value: "HUF" },
    IDR: { label: "Indonesian rupiah", key: "IDR", value: "IDR" },
    ILS: { label: "Israeli new shekel", key: "ILS", value: "ILS" },
    INR: { label: "Indian rupee", key: "INR", value: "INR" },
    IQD: { label: "Iraqi dinar", key: "IQD", value: "IQD" },
    IRR: { label: "Iranian rial", key: "IRR", value: "IRR" },
    ISK: { label: "Icelandic króna", key: "ISK", value: "ISK" },
    JMD: { label: "Jamaican dollar", key: "JMD", value: "JMD" },
    JOD: { label: "Jordanian dinar", key: "JOD", value: "JOD" },
    JPY: { label: "Japanese yen", key: "JPY", value: "JPY" },
    KES: { label: "Kenyan shilling", key: "KES", value: "KES" },
    KGS: { label: "Kyrgyzstani som", key: "KGS", value: "KGS" },
    KHR: { label: "Cambodian riel", key: "KHR", value: "KHR" },
    KMF: { label: "Comoro franc", key: "KMF", value: "KMF" },
    KPW: { label: "North Korean won", key: "KPW", value: "KPW" },
    KRW: { label: "South Korean won", key: "KRW", value: "KRW" },
    KWD: { label: "Kuwaiti dinar", key: "KWD", value: "KWD" },
    KYD: { label: "Cayman Islands dollar", key: "KYD", value: "KYD" },
    KZT: { label: "Kazakhstani tenge", key: "KZT", value: "KZT" },
    LAK: { label: "Lao kip", key: "LAK", value: "LAK" },
    LBP: { label: "Lebanese pound", key: "LBP", value: "LBP" },
    LKR: { label: "Sri Lankan rupee", key: "LKR", value: "LKR" },
    LRD: { label: "Liberian dollar", key: "LRD", value: "LRD" },
    LSL: { label: "Lesotho loti", key: "LSL", value: "LSL" },
    LYD: { label: "Libyan dinar", key: "LYD", value: "LYD" },
    MAD: { label: "Moroccan dirham", key: "MAD", value: "MAD" },
    MDL: { label: "Moldovan leu", key: "MDL", value: "MDL" },
    MGA: { label: "Malagasy ariary", key: "MGA", value: "MGA" },
    MKD: { label: "Macedonian denar", key: "MKD", value: "MKD" },
    MMK: { label: "Myanmar kyat", key: "MMK", value: "MMK" },
    MNT: { label: "Mongolian tögrög", key: "MNT", value: "MNT" },
    MOP: { label: "Macanese pataca", key: "MOP", value: "MOP" },
    MRO: { label: "Mauritanian ouguiya", key: "MRO", value: "MRO" },
    MUR: { label: "Mauritian rupee", key: "MUR", value: "MUR" },
    MVR: { label: "Maldivian rufiyaa", key: "MVR", value: "MVR" },
    MWK: { label: "Malawian kwacha", key: "MWK", value: "MWK" },
    MXN: { label: "Mexican peso", key: "MXN", value: "MXN" },
    MXV: { label: "Mexican Unidad de Inversion", key: "MXV", value: "MXV" },
    MYR: { label: "Malaysian ringgit", key: "MYR", value: "MYR" },
    MZN: { label: "Mozambican metical", key: "MZN", value: "MZN" },
    NAD: { label: "Namibian dollar", key: "NAD", value: "NAD" },
    NGN: { label: "Nigerian naira", key: "NGN", value: "NGN" },
    NIO: { label: "Nicaraguan córdoba", key: "NIO", value: "NIO" },
    NOK: { label: "Norwegian krone", key: "NOK", value: "NOK" },
    NPR: { label: "Nepalese rupee", key: "NPR", value: "NPR" },
    NZD: { label: "New Zealand dollar", key: "NZD", value: "NZD" },
    OMR: { label: "Omani rial", key: "OMR", value: "OMR" },
    PAB: { label: "Panamanian balboa", key: "PAB", value: "PAB" },
    PEN: { label: "Peruvian Sol", key: "PEN", value: "PEN" },
    PGK: { label: "Papua New Guinean kina", key: "PGK", value: "PGK" },
    PHP: { label: "Philippine peso", key: "PHP", value: "PHP" },
    PKR: { label: "Pakistani rupee", key: "PKR", value: "PKR" },
    PLN: { label: "Polish złoty", key: "PLN", value: "PLN" },
    PYG: { label: "Paraguayan guaraní", key: "PYG", value: "PYG" },
    QAR: { label: "Qatari riyal", key: "QAR", value: "QAR" },
    RON: { label: "Romanian leu", key: "RON", value: "RON" },
    RSD: { label: "Serbian dinar", key: "RSD", value: "RSD" },
    RUB: { label: "Russian ruble", key: "RUB", value: "RUB" },
    RWF: { label: "Rwandan franc", key: "RWF", value: "RWF" },
    SAR: { label: "Saudi riyal", key: "SAR", value: "SAR" },
    SBD: { label: "Solomon Islands dollar", key: "SBD", value: "SBD" },
    SCR: { label: "Seychelles rupee", key: "SCR", value: "SCR" },
    SDG: { label: "Sudanese pound", key: "SDG", value: "SDG" },
    SEK: { label: "Swedish krona", key: "SEK", value: "SEK" },
    SGD: { label: "Singapore dollar", key: "SGD", value: "SGD" },
    SHP: { label: "Saint Helena pound", key: "SHP", value: "SHP" },
    SLL: { label: "Sierra Leonean leone", key: "SLL", value: "SLL" },
    SOS: { label: "Somali shilling", key: "SOS", value: "SOS" },
    SRD: { label: "Surinamese dollar", key: "SRD", value: "SRD" },
    SSP: { label: "South Sudanese pound", key: "SSP", value: "SSP" },
    STD: { label: "São Tomé and Príncipe dobra", key: "STD", value: "STD" },
    SVC: { label: "Salvadoran colón", key: "SVC", value: "SVC" },
    SYP: { label: "Syrian pound", key: "SYP", value: "SYP" },
    SZL: { label: "Swazi lilangeni", key: "SZL", value: "SZL" },
    THB: { label: "Thai baht", key: "THB", value: "THB" },
    TJS: { label: "Tajikistani somoni", key: "TJS", value: "TJS" },
    TMT: { label: "Turkmenistani manat", key: "TMT", value: "TMT" },
    TND: { label: "Tunisian dinar", key: "TND", value: "TND" },
    TOP: { label: "Tongan paʻanga", key: "TOP", value: "TOP" },
    TRY: { label: "Turkish lira", key: "TRY", value: "TRY" },
    TTD: { label: "Trinidad and Tobago dollar", key: "TTD", value: "TTD" },
    TWD: { label: "New Taiwan dollar", key: "TWD", value: "TWD" },
    TZS: { label: "Tanzanian shilling", key: "TZS", value: "TZS" },
    UAH: { label: "Ukrainian hryvnia", key: "UAH", value: "UAH" },
    UGX: { label: "Ugandan shilling", key: "UGX", value: "UGX" },
    USD: { label: "United States dollar", key: "USD", value: "USD" },
    UYI: { label: "Uruguay Peso en Unidades Indexadas", key: "UYI", value: "UYI" },
    UYU: { label: "Uruguayan peso", key: "UYU", value: "UYU" },
    UZS: { label: "Uzbekistan som", key: "UZS", value: "UZS" },
    VEF: { label: "Venezuelan bolívar", key: "VEF", value: "VEF" },
    VND: { label: "Vietnamese đồng", key: "VND", value: "VND" },
    VUV: { label: "Vanuatu vatu", key: "VUV", value: "VUV" },
    WST: { label: "Samoan tala", key: "WST", value: "WST" },
    XAF: { label: "Central African CFA franc", key: "XAF", value: "XAF" },
    XCD: { label: "East Caribbean dollar", key: "XCD", value: "XCD" },
    XOF: { label: "West African CFA franc", key: "XOF", value: "XOF" },
    XPF: { label: "CFP franc", key: "XPF", value: "XPF" },
    XXX: { label: "No currency", key: "XXX", value: "XXX" },
    YER: { label: "Yemeni rial", key: "YER", value: "YER" },
    ZAR: { label: "South African rand", key: "ZAR", value: "ZAR" },
    ZMW: { label: "Zambian kwacha", key: "ZMW", value: "ZMW" },
    ZWL: { label: "Zimbabwean dollar", key: "ZWL", value: "ZWL" },
}
