import { create } from 'zustand'

type setMenuType = {
    menu: string
    //menuOpen: boolean
}

type setMenuAction = {
    setMenu: (menu: setMenuType['menu']) => void
    //setMenuOpen: (menuOpen: setMenuType['menuOpen']) => void
}

export const useMenuStore = create<setMenuType & setMenuAction>()((set) => ({
    menu: '',
    setMenu: (menu) => set(() => ({ menu })),
    //menuOpen: false,
    //setMenuOpen: (menuOpen) => set(() => ({ menuOpen })),
}))

type setAuthType = {
    isAuth: boolean
}

type setAuthAction = {
    setIsAuth: (isAuth: setAuthType['isAuth']) => void
}

export const useAuthStore = create<setAuthType & setAuthAction>()((set) => ({
    isAuth: false,
    setIsAuth: (isAuth) => set(() => ({ isAuth })),
}))

type setCryptoType = {
    cryptoSelected: string
}

type setCrptoAction = {
    setCryptoSelected: (cryptoSelected: setCryptoType['cryptoSelected']) => void
}

export const useCryptoStore = create<setCryptoType & setCrptoAction>()((set) => ({
    cryptoSelected: 'BTC',
    setCryptoSelected: (cryptoSelected) => set(() => ({ cryptoSelected })),
}))

type setChainType = {
    chainSelected: string
}

type setChainAction = {
    setChainSelected: (chainSelected: setChainType['chainSelected']) => void
}

export const useChainStore = create<setChainType & setChainAction>()((set) => ({
    chainSelected: 'ETH',
    setChainSelected: (chainSelected) => set(() => ({ chainSelected })),
}))

type setFiatType = {
    fiatSelected: string
}

type setFiatAction = {
    setFiatSelected: (fiatSelected: setFiatType['fiatSelected']) => void
}

export const useFiatStore = create<setFiatType & setFiatAction>()((set) => ({
    fiatSelected: 'ANY',
    setFiatSelected: (fiatSelected) => set(() => ({ fiatSelected })),
}))

type setLoadingType = {
    loading: boolean
}

type setLoadingAction = {
    setLoading: (loading: setLoadingType['loading']) => void
}

export const useloadingStore = create<setLoadingType & setLoadingAction>()((set) => ({
    loading: true,
    setLoading: (loading) => set(() => ({ loading })),
}))


type timerType = {
    timer: number
}

type timerAction = {
    incTimer: () => void
    resTimer: () => void
}

export const useTimerStore = create<timerType & timerAction>()((set) => ({
    timer: 0,
    incTimer: () => set((state) => ({ timer: state.timer + 1 })),
    resTimer: () => set({ timer: 0 }),
}))


type filterType = {
    filterList: {
        [key: string]: {
            label: string
            value: string
            checked: boolean
        }
    }
}

type setFilterAction = {
    setChecked: (key: string, value: boolean) => void
}

export const useFilterStore = create<filterType & setFilterAction>()((set) => ({
    filterList: {
        merchant_online: { label: "List ads of merchants who are currently online.", value: "merchant_online", checked: false },
        average_time: { label: "Sort ads based on fastest average trade time.", value: "average_time", checked: false },
        feedback_score: { label: "Sort ads based on the highest feedback score.", value: "feedback_score", checked: false },
        order_time: { label: "Sort ads based on the trade completion score.", value: "order_time", checked: false },
        payment_time: { label: "Sort ads based on the shortest auto-cancel time.", value: "payment_time", checked: false },
        /*         non_custodial: { label: "Display ads only for non-custodial wallets.", value: "non_custodial", checked: false },
                custodial: { label: "Display ads only for custodial wallets.", value: "custodial", checked: false }, */
    },
    setChecked: (key, value) => set((state) => ({ filterList: { ...state.filterList, [key]: { ...state.filterList[key], checked: value } } })),
}))

