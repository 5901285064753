import { rem } from "@mantine/core";
import { IconBasketDown, IconBasketUp, IconCoins, IconExchange, IconSwitch2, IconWallet } from "@tabler/icons-react";
import { ReactElement } from "react";

type menuType = {
    [key: string]: {
        key: string
        value: string
        icon: ReactElement
    };
};

export const menuList: menuType = {
    "buy": { key: "buy", value: "Buy", icon: <IconBasketUp style={{ width: rem(24), height: rem(24) }} /> },
    "sell": { key: "sell", value: "Sell", icon: <IconBasketDown style={{ width: rem(24), height: rem(24) }} /> },
    "swap": { key: "swap", value: "Swap", icon: <IconExchange style={{ width: rem(24), height: rem(24) }} /> },
    "staking": { key: "staking", value: "Staking", icon: <IconCoins style={{ width: rem(24), height: rem(24) }} /> },
    "bridge": { key: "bridge", value: "Bridge", icon: <IconSwitch2 style={{ width: rem(24), height: rem(24) }} /> },
    /* "history": { key: "history", value: "History", icon: <IconHistory style={{ width: rem(24), height: rem(24) }}/> }, */
    "wallet": { key: "wallet", value: "Wallet", icon: <IconWallet style={{ width: rem(24), height: rem(24) }} /> },
    /* "account": { key: "account", value: "Account", icon: <IconUser style={{ width: rem(24), height: rem(24) }}/> }, */
}
